import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    newMessages: null,
    messages: null,
  },
  getters: { },
  mutations: {
    setNewMessages(state, payload) {
      state.newMessages = payload.data
    },
    setMessages(state, payload) {
      state.messages = payload.data
    },
  },
  actions: {
    fetchMessage(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/messages/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchNewMessages({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/messages/unread')
          .then(response => {
            if (response.data) {
              commit('setNewMessages', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchMessages({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/messages/system')
          .then(response => {
            if (response.data) {
              commit('setMessages', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}

import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
// eslint-disable-next-line import/no-cycle
import auth from './auth'
import home from './home'
import billing from './billing'
import invoice from './invoice'
import tickets from './tickets'
import messages from './messages'
import setting from './setting'
import users from './users'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import optional from './optional'
import affiliate from './affiliate'
import packages from './packages'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    home,
    billing,
    invoice,
    setting,
    tickets,
    messages,
    users,
    appConfig,
    verticalMenu,
    optional,
    affiliate,
    packages,
  },
  strict: process.env.DEV,
})

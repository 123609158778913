import axios from '@/libs/axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    affiliateData: null,
    registeredAffiliateStat: null,
    affiliateTickets: null,
    affiliateNewsletter: null,
  },
  getters: { },
  mutations: {
    SET_AFFILIATE_DATA(state, payload) {
      state.affiliateData = payload
    },
    SET_REGISTERED_AFFILIATE_STAT(state, payload) {
      state.registeredAffiliateStat = payload
    },
    SET_AFFILIATE_TICKETS(state, payload) {
      state.affiliateTickets = payload.data
    },
    SET_AFFILIATE_NEWSLETTER(state, payload) {
      state.affiliateNewsletter = payload.data
    },
  },
  actions: {
    fetchAffiliateData({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.affiliateData === null) {
          axios
            .get('/api/affiliates/affiliate/get_user_details')
            .then(response => {
              if (response.data) {
                commit('SET_AFFILIATE_DATA', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.affiliateData)
      })
    },
    fetchRegisteredAffiliateStat({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/affiliates/affiliate_statistic')
          .then(response => {
            if (response.data) {
              for (const item of response.data.users) {  // eslint-disable-line

                item.customType = 'Referral Link (normal)'
                if (item.cpa_referred_by) item.customType = 'CPA Link'

                item.subscribed_date = moment(new Date(item.subscribed_date)).format('DD MMM YYYY')

                if (item.revenue_share === null) item.revenue_share = 0
                item.customRevenue = ` € ${parseFloat(item.revenue_share).toFixed(2)}`

                item.is_credit_note = Number(item.is_credit_note)
                if (item.is_credit_note === 1) item.invoice_paid_date = 'N/A'
                else if (item.updated_at) item.invoice_paid_date = moment(new Date(item.invoice_paid_date)).format('DD MMM YYYY')
                else item.invoice_paid_date = 'N/A'
              }
              commit('SET_REGISTERED_AFFILIATE_STAT', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAffiliateTickets({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/affiliates_events')
          .then(response => {
            if (response.data) {
              commit('SET_AFFILIATE_TICKETS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAffiliateNewsletter({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/affiliates_events/newsletter')
          .then(response => {
            if (response.data) {
              commit('SET_AFFILIATE_NEWSLETTER', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}

import axios from '@/libs/axios'
import Vue from 'vue'

function utlFormatHttp(url) {
  if (typeof url !== 'undefined' && url.trim() !== '') {
    const pattern = /^((http|https):\/\/)/
    if (!pattern.test(url)) {
      url = `http://${url}` // eslint-disable-line
    }
    return url
  }
  return ''
}

export default {
  namespaced: true,
  state: {
    allUsers: null,
    subscribedUsers: null,
    trialUsers: null,
    pendingUsers: null,
    registeredUsers: null,
    leadsUsers: null,
    canceledUsers: null,
    expiredUsers: null,
    affiliatesUsers: null,
    pendingLeads: null,
    callbackLeads: null,
    externalLeads: null,
    calledLeads: null,
    rejectedLeads: null,
    userReports: null,
    admins: null,
    clicksSearch: null,
    // initialUsers: null,
  },
  getters: {
    getUserByUID: state => uid => state.allUsers.find(obj => obj.uid === uid),
  },
  mutations: {
    SET_PENDING_LEADS(state, payload) {
      state.pendingLeads = payload
    },
    SET_CALLBACK_LEADS(state, payload) {
      state.callbackLeads = payload
    },
    SET_EXTERNAL_LEADS(state, payload) {
      state.externalLeads = payload
    },
    SET_CALLED_LEADS(state, payload) {
      state.calledLeads = payload
    },
    SET_REJECTED_LEADS(state, payload) {
      state.rejectedLeads = payload
    },
    SET_ALL_USERS(state, payload) {
      state.allUsers = payload
    },
    // SET_INITIAL_USERS(state, payload) {
    //   state.initialUsers = payload
    // },
    setAdmins(state, payload) {
      state.admins = payload
      state.admins.unshift({ uid: null, forename: 'Nobody' })
    },
    SET_USER_REPORTS(state, payload) {
      state.userReports = payload
    },
    SET_SUBSCRIBED_USERS(state, payload) {
      state.subscribedUsers = payload
    },
    SET_TRIAL_USERS(state, payload) {
      state.trialUsers = payload
    },
    SET_PENDING_USERS(state, payload) {
      state.pendingUsers = payload
    },
    SET_REGISTERED_USERS(state, payload) {
      state.registeredUsers = payload
    },
    SET_LEADS_USERS(state, payload) {
      state.leadsUsers = payload
    },
    SET_CANCELED_USERS(state, payload) {
      state.canceledUsers = payload
    },
    SET_EXPIRED_USERS(state, payload) {
      state.expiredUsers = payload
    },
    SET_AFFILIATES_USERS(state, payload) {
      state.affiliatesUsers = payload
    },
    SET_CLICKS_SEARCH(state, payload) {
      state.clicksSearch = payload
    },
    UPDATE_USER(state, payload) {
      if (state.allUsers) {
        const resultIndex = state.allUsers.findIndex(x => x.uid === payload.uid)
        Vue.set(state.allUsers, resultIndex, payload)
      }
    },
    INSERT_CANCELED_USER(state, payload) {
      if (state.canceledUsers) state.canceledUsers.unshift(payload)
    },
    INSERT_CALLBACK_LEADS(state, payload) {
      if (state.callbackLeads) state.callbackLeads.unshift(payload)
    },
    INSERT_REJECTED_LEADS(state, payload) {
      if (state.rejectedLeads) state.rejectedLeads.unshift(payload)
    },
    INSERT_CALLED_LEADS(state, payload) {
      if (state.calledLeads) state.calledLeads.unshift(payload)
    },
    INSERT_PENDING_LEADS(state, payload) {
      if (state.pendingLeads) state.pendingLeads.unshift(payload)
    },
    REMOVE_PENDING_LEADS(state, id) {
      if (state.pendingLeads) {
        const userIndex = state.pendingLeads.findIndex(x => x.id === id)
        Vue.delete(state.pendingLeads, userIndex)
      }
    },
    REMOVE_CALLBACK_LEADS(state, id) {
      if (state.callbackLeads) {
        const userIndex = state.callbackLeads.findIndex(x => x.id === id)
        Vue.delete(state.callbackLeads, userIndex)
      }
    },
    REMOVE_CALLED_LEADS(state, id) {
      if (state.calledLeads) {
        const userIndex = state.calledLeads.findIndex(x => x.id === id)
        Vue.delete(state.calledLeads, userIndex)
      }
    },
    REMOVE_REJECTED_LEADS(state, id) {
      if (state.rejectedLeads) {
        const userIndex = state.rejectedLeads.findIndex(x => x.id === id)
        Vue.delete(state.rejectedLeads, userIndex)
      }
    },
    REMOVE_USER(state, payload) {
      if (state.allUsers) {
        const userIndex = state.allUsers.findIndex(x => x.uid === payload.uid)
        Vue.delete(state.allUsers, userIndex)
      }
    },
    DELETE_LEAD_USER(state, payload) {
      if (state.leadsUsers) {
        const userIndex = state.leadsUsers.findIndex(x => x.uid === payload.uid)
        Vue.delete(state.leadsUsers, userIndex)
      }
    },
  },
  actions: {
    setClickSearch(ctx, payload) {
      ctx.commit('SET_CLICKS_SEARCH', payload)
    },
    fetchPendingLeads({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.pendingLeads === null) {
          axios
            .get('/api/users/leads/1')
            .then(response => {
              if (response.data) {
                for (const item of response.data) {  // eslint-disable-line
                  item.website = utlFormatHttp(item.website)
                }
                commit('SET_PENDING_LEADS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.pendingLeads)
      })
    },
    refreshPendingLeads({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users/leads/1')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                item.website = utlFormatHttp(item.website)
              }
              commit('SET_PENDING_LEADS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchCallbackLeads({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.callbackLeads === null) {
          axios
            .get('/api/users/leads/2')
            .then(response => {
              if (response.data) {
                for (const item of response.data) {  // eslint-disable-line
                  if (item.website.trim() !== '') item.website = utlFormatHttp(item.website)
                }
                commit('SET_CALLBACK_LEADS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.callbackLeads)
      })
    },
    fetchExternalLeads({ commit }) {
      return new Promise((resolve, reject) => {
        // if (state.externalLeads === null) {
        axios
          .get('/api/users/leads/4')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                if (item.website.trim() !== '') item.website = utlFormatHttp(item.website)
              }
              commit('SET_EXTERNAL_LEADS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
        // } else resolve(state.externalLeads)
      })
    },
    refreshCallbackLeads({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users/leads/2')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                if (item.website.trim() !== '') item.website = utlFormatHttp(item.website)
              }
              commit('SET_CALLBACK_LEADS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchCalledLeads({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.calledLeads === null) {
          axios
            .get('/api/users/leads/3')
            .then(response => {
              if (response.data) {
                for (const item of response.data) {  // eslint-disable-line
                  if (item.website.trim() !== '') item.website = utlFormatHttp(item.website)
                }
                commit('SET_CALLED_LEADS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.calledLeads)
      })
    },
    refreshCalledLeads({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users/leads/3')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                if (item.website.trim() !== '') item.website = utlFormatHttp(item.website)
              }
              commit('SET_CALLED_LEADS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchRejectedLeads({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.rejectedLeads === null) {
          axios
            .get('/api/users/leads/0')
            .then(response => {
              if (response.data) {
                for (const item of response.data) {  // eslint-disable-line
                  if (item.website.trim() !== '') item.website = utlFormatHttp(item.website)
                }
                commit('SET_REJECTED_LEADS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.rejectedLeads)
      })
    },
    refreshRejectedLeads({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users/leads/0')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                if (item.website.trim() !== '') item.website = utlFormatHttp(item.website)
              }
              commit('SET_REJECTED_LEADS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAllUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.allUsers === null) {
          axios
            .get('/api/users')
            .then(response => {
              if (response.data) {
                commit('SET_ALL_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.allUsers)
      })
    },
    fetchAllUsersServer(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/users?page=${payload.page}`, payload)
          .then(response => {
            if (response.data) {
              ctx.commit('SET_ALL_USERS', response.data.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    // fetchInitialUsers({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/api/users/initial')
    //       .then(response => {
    //         if (response.data) {
    //           commit('SET_INITIAL_USERS', response.data)
    //           resolve(response.data)
    //         }
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    fetchAdmins({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.admins === null) {
          axios
            .get('/api/users/admins')
            .then(response => {
              if (response.data) {
                commit('setAdmins', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.admins)
      })
    },
    fetchUserReports({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.userReports === null) {
          axios
            .get('/api/users/reports')
            .then(response => {
              if (response.data) {
                commit('SET_USER_REPORTS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.userReports)
      })
    },
    fetchSubscribedUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.subscribedUsers === null) {
          axios
            .get('/api/users/active')
            .then(response => {
              if (response.data) {
                commit('SET_SUBSCRIBED_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.subscribedUsers)
      })
    },
    fetchTrialUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.trialUsers === null) {
          axios
            .get('/api/users/trial')
            .then(response => {
              if (response.data) {
                commit('SET_TRIAL_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.trialUsers)
      })
    },
    fetchPendingUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.pendingUsers === null) {
          axios
            .get('/api/users/pending')
            .then(response => {
              if (response.data) {
                commit('SET_PENDING_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.pendingUsers)
      })
    },
    fetchRegisteredUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.registeredUsers === null) {
          axios
            .get('/api/users/registered')
            .then(response => {
              if (response.data) {
                commit('SET_REGISTERED_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.registeredUsers)
      })
    },
    fetchLeadsUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.leadsUsers === null) {
          axios
            .get('/api/users/leads')
            .then(response => {
              if (response.data) {
                commit('SET_LEADS_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.leadsUsers)
      })
    },
    deleteLeadUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/users/leads', { data: { id } })
          .then(response => {
            if (response.data) {
              commit('DELETE_LEAD_USER', id)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchCanceledUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.canceledUsers === null) {
          axios
            .get('/api/users/canceled')
            .then(response => {
              if (response.data) {
                commit('SET_CANCELED_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.canceledUsers)
      })
    },
    fetchExpiredUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.expiredUsers === null) {
          axios
            .get('/api/users/expired')
            .then(response => {
              if (response.data) {
                commit('SET_EXPIRED_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.expiredUsers)
      })
    },
    fetchAffiliatesUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.affiliatesUsers === null) {
          axios
            .get('/api/users/affiliates')
            .then(response => {
              if (response.data) {
                commit('SET_AFFILIATES_USERS', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.affiliatesUsers)
      })
    },
    fetchUserActivity(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/activity/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchUserLogins(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/logins/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchUserChanges(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/changes/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchUserMessages(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/messages/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },

    // beta speed testing
    fetchUserSplitDetails(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/split_details/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },

    fetchUserDetails(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/details/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    cancelUser({ commit }, uid) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/users/cancel', { uid })
          .then(response => {
            if (response.data) {
              commit('UPDATE_USER', response.data)
              commit('INSERT_CANCELED_USER', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    deleteUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/users/delete', { data: { id } })
          .then(response => {
            if (response.data) {
              commit('REMOVE_USER', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateLeadStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/users/leads', { id: payload.id, status: payload.status })
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateUserStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/users/status', { id: payload.id, status: payload.status })
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    activate(ctx, payload) {
      return axios.patch(`/api/system-live/${payload}`)
    },
    sendEmailInvite(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/admin/demo-user/${id}/send-invite-mail`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    saveScriptSettings(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/store-script-settings', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    sendUserInvite(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/invites', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    sendSubExpMail(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/sub-mail/expired/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    sendSubCanceledMail(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/sub-mail/canceled/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    sendPendingPaymentMail(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/pending-payment/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateTrialPeriod(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/users/trial-period', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    addReferral(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/referral', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    setIP(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/set-ips', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    expireTagRequest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users/expire-tag', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchUsersTag(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/admin/get_tags', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}

import axios from '@/libs/axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    billingData: null,
    allBankingProfiles: null,
    discountHistories: null,
    balanceData: null,
  },
  getters: {
    getBillingByUID: state => uid => state.billingData.find(obj => obj.uid === uid),
    getBankingProfilesByUID: state => id => state.allBankingProfiles.find(obj => obj.id === id),
  },
  mutations: {
    SET_BILLING_DATA(state, payload) {
      state.billingData = payload
    },
    SET_DISCOUNT_HISTORY_DATA(state, payload) {
      state.discountHistories = payload
    },
    SET_BANKING_PROFILE(state, payload) {
      state.allBankingProfiles = payload
    },
    UPDATE_BANKING_PROFILE(state, payload) {
      if (state.allBankingProfiles) {
        const resultIndex = state.allBankingProfiles.findIndex(x => x.id === payload.id)
        Vue.set(state.allBankingProfiles, resultIndex, payload)
      }
    },
    REMOVE_BANKING_PROFILE(state, id) {
      if (state.allBankingProfiles) {
        const bankingProfilesIndex = state.allBankingProfiles.findIndex(x => x.id === id)
        Vue.delete(state.allBankingProfiles, bankingProfilesIndex)
      }
    },
    INSERT_BANKING_PROFILE(state, payload) {
      state.allBankingProfiles.unshift(payload)
    },
    SET_BALANCE_DATA(state, payload) {
      state.balanceData = payload
    },
  },
  actions: {
    fetchBilling({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.billingData === null) {
          axios
            .get('/api/billing')
            .then(response => {
              if (response.data) {
                commit('SET_BILLING_DATA', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.billingData)
      })
    },
    fetchUserBilling(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/billing/admin/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    addDiscount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/billing/discount', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    generateRenewPFI(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/billing/generate-renew-pfi', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    generateRecurrentPFI(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/billing/generate-recurrent-pfi', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAllBankingProfiles({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/banking_profile')
          .then(response => {
            if (response.data) {
              commit('SET_BANKING_PROFILE', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchDiscountHistory({ commit }, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/banking_profile/discount_histories/${uid}`)
          .then(response => {
            if (response.data) {
              commit('SET_DISCOUNT_HISTORY_DATA', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    deleteBankingProfile({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/banking_profile/${id}`)
          .then(response => {
            if (response.data.action === 'OK') {
              commit('REMOVE_BANKING_PROFILE', id)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    addBankingProfile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/banking_profile', payload)
          .then(response => {
            if (response.data.action === 'OK') {
              /* eslint-disable no-param-reassign */
              payload.id = Number(response.data.id)
              /* eslint-enable no-param-reassign */
              commit('INSERT_BANKING_PROFILE', payload)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    editBankingProfile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload
        axios
          .put(`/api/banking_profile/${id}`, payload)
          .then(response => {
            if (response.data) {
              commit('UPDATE_BANKING_PROFILE', payload)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchBalance({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.balanceData === null) {
          axios
            .get('/api/wire_transfer/getBalancePayments')
            .then(response => {
              if (response.data) {
                commit('SET_BALANCE_DATA', response.data)
                resolve(response.data.balancePayments)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.balanceData)
      })
    },
  },
}

import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    ticketTypes: null,
    ticketStatuses: null,
    openTickets: null,
    closedTickets: null,
  },
  getters: { },
  mutations: {
    SET_TICKET_TYPES(state, payload) {
      state.ticketTypes = payload
    },
    setTicketStatuses(state, payload) {
      state.ticketStatuses = payload
    },
    setOpenTickets(state, payload) {
      state.openTickets = payload
    },
    setClosedTickets(state, payload) {
      state.closedTickets = payload
    },
  },
  actions: {
    fetchOpenTickets({ commit }, uid) {
      let uri = '/api/tickets/open/list'
      if (uid) uri = `/api/tickets/open/list?uid=${uid}`
      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => {
            if (response.data) {
              commit('setOpenTickets', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchTicket(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/tickets/${id}`)
          .then(response => {
            if (response.data) {
              for (const item of response.data.data.messages) {  // eslint-disable-line
                item.editbutton = false
              }
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchClosedTickets({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.closedTickets === null) {
          axios
            .get('/api/tickets/closed/list')
            .then(response => {
              if (response.data) {
                commit('setClosedTickets', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else {
          resolve(state.closedTickets)
        }
      })
    },
    fetchClosedTicketsFresh({ commit }, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/tickets/closed/list?uid=${uid}`)
          .then(response => {
            if (response.data) {
              commit('setClosedTickets', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchTicketTypes({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.ticketTypes === null) {
          axios
            .get('/api/tickets/types/list')
            .then(response => {
              if (response.data) {
                commit('SET_TICKET_TYPES', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else {
          resolve(state.ticketTypes)
        }
      })
    },
    fetchTicketStatuses({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.ticketTypes === null) {
          axios
            .get('/api/tickets/statuses/list')
            .then(response => {
              if (response.data) {
                commit('setTicketStatuses', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else {
          resolve(state.ticketStatuses)
        }
      })
    },
    fetchToReply(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/tickets/get_reply/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },

    fetchTemplates(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/tickets/templates', params)
          .then(response => {
            if (response.data) {
              resolve(response.data.data)
            }
          })
          .catch(error => reject(error))
      })
    },

    fetchTemplates2() {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/tickets/templates2')
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },

    deleteTemplate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/tickets/template/delete/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}

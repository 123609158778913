import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/registration_not_complete',
      component: () => import('@/views/home/RegistrationNotComplete.vue'),
      meta: {
        pageTitle: 'Home',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/leads/:id/add-note',
      name: 'add-note',
      component: () => import('@/views/form-leads/LeadsNotes.vue'),
      meta: {
        pageTitle: 'Add note',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Add note',
            active: true,
          },
        ],
      },
    },
    {
      path: '/form-leads/external',
      name: 'form-leads-external',
      component: () => import('@/views/form-leads/External.vue'),
      meta: {
        pageTitle: 'External Leads',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Leads',
            active: true,
          },
        ],
      },
    },
    {
      path: '/form-leads/pending',
      name: 'form-leads-pending',
      component: () => import('@/views/form-leads/Pending.vue'),
      meta: {
        pageTitle: 'Pending Leads',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Leads',
            active: true,
          },
          {
            text: 'Pending',
            active: true,
          },
        ],
      },
    },
    {
      path: '/form-leads/to-call-back',
      name: 'form-leads-callback',
      component: () => import('@/views/form-leads/ToCallBack.vue'),
      meta: {
        pageTitle: 'To Call Back Leads',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Leads',
            active: true,
          },
          {
            text: 'To Call Back',
            active: true,
          },
        ],
      },
    },
    {
      path: '/form-leads/called',
      name: 'form-leads-called',
      component: () => import('@/views/form-leads/Called.vue'),
      meta: {
        pageTitle: 'Called Leads',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Leads',
            active: true,
          },
          {
            text: 'Called',
            active: true,
          },
        ],
      },
    },
    {
      path: '/form-leads/rejected',
      name: 'form-leads-rejected',
      component: () => import('@/views/form-leads/Rejected.vue'),
      meta: {
        pageTitle: 'Rejected Leads',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Leads',
            active: true,
          },
          {
            text: 'Rejected',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/details/:id',
      name: 'user-details',
      component: () => import('@/views/users/Details.vue'),
      meta: {
        pageTitle: 'User Details',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/all',
      name: 'users-all',
      component: () => import('@/views/users/All.vue'),
      meta: {
        pageTitle: 'All Users',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/subscribed',
      name: 'users-subscribed',
      component: () => import('@/views/users/Subscribed.vue'),
      meta: {
        pageTitle: 'Subscribed Users',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Subscribed',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/trial-users',
      name: 'users-trial-users',
      component: () => import('@/views/users/TrialUsers.vue'),
      meta: {
        pageTitle: 'Trial Users',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Trial',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/pending-users',
      name: 'users-pending-users',
      component: () => import('@/views/users/PendingUsers.vue'),
      meta: {
        pageTitle: 'Pending Users',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Pending',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/registered',
      name: 'users-registered',
      component: () => import('@/views/users/Registered.vue'),
      meta: {
        pageTitle: 'Registered Users',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Registered',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create_limited_account',
      name: 'create_limited_account',
      component: () => import('@/views/LimitedAccount.vue'),
      meta: {
        pageTitle: 'Create Limited Account',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'Create Limited Account',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/leads',
      name: 'users-leads',
      component: () => import('@/views/users/Leads.vue'),
      meta: {
        pageTitle: 'Leads',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Leads',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/canceled',
      name: 'users-canceled',
      component: () => import('@/views/users/Canceled.vue'),
      meta: {
        pageTitle: 'Canceled Users',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Canceled',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/expired',
      name: 'users-expired',
      component: () => import('@/views/users/Expired.vue'),
      meta: {
        pageTitle: 'Expired/Suspended Users',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Expired/Suspended',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/affiliates',
      name: 'users-affiliates',
      component: () => import('@/views/users/Affiliates.vue'),
      meta: {
        pageTitle: 'Affiliates',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users/all',
          },
          {
            text: 'Affiliates',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/affiliate-conference-tickets',
    //   name: 'affiliate-conference-tickets',
    //   component: () => import('@/views/affiliate-conference/AffiliateTickets.vue'),
    //   meta: {
    //     pageTitle: 'Affiliate Conference',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'Affiliates Tickets (FREE and Paid)',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/affiliate-conference-tickets-vip',
    //   name: 'affiliate-conference-tickets-vip',
    //   component: () => import('@/views/affiliate-conference/AffiliateTicketsVip.vue'),
    //   meta: {
    //     pageTitle: 'Affiliate Conference',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'VIP/Sponsor/Speaker Tickets',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/affiliate-conference-event',
    //   name: 'affiliate-conference-event',
    //   component: () => import('@/views/affiliate-conference/AffiliateEventsSponsors.vue'),
    //   meta: {
    //     pageTitle: 'Affiliate Conference',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'Event Sponsors Requests',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/affiliate-conference-speakers',
    //   name: 'affiliate-conference-speakers',
    //   component: () => import('@/views/affiliate-conference/AffiliateEventsSpeakers.vue'),
    //   meta: {
    //     pageTitle: 'Affiliate Conference',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'Event Speakers Requests',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/affiliate-conference-newsletter',
    //   name: 'affiliate-conference-newsletter',
    //   component: () => import('@/views/affiliate-conference/AffiliateNewsletter.vue'),
    //   meta: {
    //     pageTitle: 'Affiliate Conference',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'Newsletter Subscribers',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/affiliate-conference-invoices',
    //   name: 'affiliate-conference-invoices',
    //   component: () => import('@/views/affiliate-conference/invoices/List.vue'),
    //   meta: {
    //     pageTitle: 'Affiliate Conference',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'Tickets Invoices',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/affiliate-conference-invoice/:id/edit',
    //   name: 'affiliate-conference-invoice/:id/edit',
    //   component: () => import('@/views/affiliate-conference/invoices/Edit.vue'),
    //   meta: {
    //     pageTitle: 'Tickets Invoices',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'Edit Invoice',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/affiliate-conference-invoice/:id/show',
    //   name: 'affiliate-conference-invoice/:id/show',
    //   component: () => import('@/views/affiliate-conference/invoices/Show.vue'),
    //   meta: {
    //     pageTitle: 'Tickets Invoices',
    //     action: 'read',
    //     resource: 'Admin',
    //     breadcrumb: [
    //       {
    //         text: 'Show Invoice',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/request-payment',
      name: 'request-payment',
      component: () => import('@/views/RequestPayment.vue'),
      meta: {
        pageTitle: 'Request Payment',
        action: 'read',
        resource: 'Affiliate',
        breadcrumb: [
          {
            text: 'Request',
            active: true,
          },
        ],
      },
    },
    {
      path: '/custom-dns',
      name: 'custom-dns',
      component: () => import('@/views/CustomDNS.vue'),
      meta: {
        pageTitle: 'Custom DNS',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'Manage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/affiliate-program',
      name: 'affiliate-program',
      component: () => import('@/views/AffiliateProgram.vue'),
      meta: {
        pageTitle: 'Affiliate Program',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'Affiliate Program',
            active: true,
          },
        ],
      },
    },
    {
      path: '/affiliat-user-statistics',
      name: 'affiliat-user-statistics',
      component: () => import('@/views/affiliate/AffiliateUserStats.vue'),
      meta: {
        pageTitle: 'Affiliate Program',
        action: 'read',
        resource: 'AffiliateUser',
        breadcrumb: [
          {
            text: 'Affiliate Program',
            active: true,
          },
          {
            text: 'Statistics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/affiliat-user-payments',
      name: 'affiliat-user-payments',
      component: () => import('@/views/affiliate/AffiliateUserPayments.vue'),
      meta: {
        pageTitle: 'Affiliate Program',
        action: 'read',
        resource: 'AffiliateUser',
        breadcrumb: [
          {
            text: 'Affiliate Program',
            active: true,
          },
          {
            text: 'Payments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/anti-fraud-admin',
      name: 'anti-fraud-admin',
      component: () => import('@/views/antifraud/AntiFraudAmin.vue'),
      meta: {
        pageTitle: 'Anti-fraud',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Anti-fraud Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/anti-fraud-clients',
      name: 'anti-fraud-clients',
      component: () => import('@/views/antifraud/AntiFraudClients.vue'),
      meta: {
        pageTitle: 'Anti-fraud',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Anti-fraud Clients',
            active: true,
          },
        ],
      },
    },
    {
      path: '/anti-fraud-credits',
      name: 'anti-fraud-credits',
      component: () => import('@/views/antifraud/AntiFraudUser.vue'),
      meta: {
        pageTitle: 'Anti-fraud',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'Anti-fraud Packages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/new-ticket',
      name: 'tickets-new-ticket',
      component: () => import('@/views/tickets/NewTicket.vue'),
      meta: {
        pageTitle: 'New Ticket',
        action: 'read',
        resource: 'Ticket',
        breadcrumb: [
          {
            text: 'Support Tickets',
            active: true,
          },
          {
            text: 'Request Support',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/new-ticket/:id',
      component: () => import('@/views/tickets/NewTicket.vue'),
      meta: {
        pageTitle: 'New Ticket',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Support Tickets',
            active: true,
          },
          {
            text: 'Request Support',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/open-tickets',
      name: 'tickets-open-tickets',
      component: () => import('@/views/tickets/OpenTickets.vue'),
      meta: {
        pageTitle: 'Open Tickets',
        action: 'read',
        resource: 'Ticket',
        breadcrumb: [
          {
            text: 'Support Tickets',
            active: true,
          },
          {
            text: 'Open',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/closed-tickets',
      name: 'tickets-closed-tickets',
      component: () => import('@/views/tickets/ClosedTickets.vue'),
      meta: {
        pageTitle: 'Closed Tickets',
        action: 'read',
        resource: 'Ticket',
        breadcrumb: [
          {
            text: 'Support Tickets',
            active: true,
          },
          {
            text: 'Closed',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/statistics',
      name: 'tickets-statistics',
      component: () => import('@/views/tickets/Statistics.vue'),
      meta: {
        pageTitle: 'Tickets Statistics',
        action: 'read',
        resource: 'Ticket',
        breadcrumb: [
          {
            text: 'Support Tickets',
            active: true,
          },
          {
            text: 'Tickets Statistics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/response-templates',
      name: 'response-templates',
      component: () => import('@/views/tickets/TicketTemplates.vue'),
      meta: {
        pageTitle: 'Ticket Response Templates',
        action: 'read',
        resource: 'Ticket',
        breadcrumb: [
          {
            text: 'Support Tickets',
            active: true,
          },
          {
            text: 'Response Templates',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tickets/:id',
      name: 'tickets-show-ticket',
      component: () => import('@/views/tickets/Show.vue'),
      meta: {
        pageTitle: 'Ticket details',
        action: 'read',
        resource: 'Ticket',
        breadcrumb: [
          {
            text: 'Tickets',
            to: '/tickets/open-tickets',
          },
          {
            text: 'Ticket details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/messages/systemadmin',
      name: 'adminmessages',
      component: () => import('@/views/messages/ListAdmin.vue'),
      meta: {
        pageTitle: 'Messages',
        action: 'read',
        resource: 'Message',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
          {
            text: 'System messages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/messages/system',
      name: 'messages',
      component: () => import('@/views/messages/List.vue'),
      meta: {
        pageTitle: 'Messages',
        action: 'read',
        resource: 'Message',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
          {
            text: 'System messages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/messages/:id',
      component: () => import('@/views/messages/Show.vue'),
      meta: {
        pageTitle: 'Message details',
        action: 'read',
        resource: 'Message',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
          {
            text: 'Message details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/packages',
      name: 'packages',
      component: () => import('@/views/packages/UserListUpgrades.vue'),
      meta: {
        pageTitle: 'Packages',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'User list upgrades',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/invoices-register/:last30days',
      component: () => import('@/views/billing/InvoicesRegister.vue'),
      meta: {
        pageTitle: 'Invoices Register',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Invoices Register',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/invoices-register',
      name: 'billing-invoices-register',
      component: () => import('@/views/billing/InvoicesRegister.vue'),
      meta: {
        pageTitle: 'Invoices Register',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Invoices Register',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/pending-forma-invoices',
      name: 'billing-pending-forma-invoices',
      component: () => import('@/views/billing/PendingProFormaInvoices.vue'),
      meta: {
        pageTitle: 'Pending Pro Forma Invoices',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Pending Pro Forma',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/pending-forma-invoices/:last30days',
      component: () => import('@/views/billing/PendingProFormaInvoices.vue'),
      meta: {
        pageTitle: 'Pending Pro Forma Invoices',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Pending Pro Forma',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/overdue-forma-invoices',
      name: 'billing-overdue-forma-invoices',
      component: () => import('@/views/billing/OverdueProFormaInvoices.vue'),
      meta: {
        pageTitle: 'Overdue Pro Forma Invoices',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Overdue Pro Forma',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/overdue-forma-invoices/:last30days',
      component: () => import('@/views/billing/OverdueProFormaInvoices.vue'),
      meta: {
        pageTitle: 'Overdue Pro Forma Invoices',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Overdue Pro Forma',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/payments-register',
      name: 'billing-payments-register',
      component: () => import('@/views/billing/PaymentsRegister.vue'),
      meta: {
        pageTitle: 'Payments Register',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Payments Register',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/banking-profiles',
      name: 'billing-banking-profiles',
      component: () => import('@/views/billing/banking-profile/BankingProfiles.vue'),
      meta: {
        pageTitle: 'Banking Profiles',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Banking Profiles',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/add-banking-profiles',
      name: 'billing-add-banking-profiles',
      component: () => import('@/views/billing/banking-profile/AddBankingProfile.vue'),
      meta: {
        pageTitle: 'Banking Profiles',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Banking Profiles',
            active: true,
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/edit-banking-profiles/:id',
      name: 'billing-edit-banking-profiles',
      component: () => import('@/views/billing/banking-profile/EditBankingProfile.vue'),
      meta: {
        pageTitle: 'Banking Profiles',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Banking Profiles',
            active: true,
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/ext-users-profiles',
      name: 'billing-ext-users-profiles',
      component: () => import('@/views/billing/ExtUsersProfiles.vue'),
      meta: {
        pageTitle: 'Ext. Users Profiles',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Ext. Users Profiles',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/affiliate-payments',
      name: 'billing-affiliate-payments',
      component: () => import('@/views/billing/affiliate/AffiliatePayments.vue'),
      meta: {
        pageTitle: 'Affiliate Payments',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Affiliate Payments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/affiliate-payments-detail/:id',
      name: 'billing-affiliate-payments-datail',
      component: () => import('@/views/billing/affiliate/AffiliatePaymentsDetail.vue'),
      meta: {
        pageTitle: 'Affiliate Payments',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Affiliate Payments',
            active: true,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/wire-transfers',
      name: 'billing-wire-transfers',
      component: () => import('@/views/billing/WireTransfers.vue'),
      meta: {
        pageTitle: 'Wire Transfers',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Wire Transfers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/changelog',
      name: 'changelog',
      component: () => import('@/views/logs/ChangeLog.vue'),
      meta: {
        pageTitle: 'Changelog',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Create/Edit changelog',
            active: true,
          },
        ],
      },
    },
    {
      path: '/global-logs',
      name: 'global-logs',
      component: () => import('@/views/logs/GlobalLogs.vue'),
      meta: {
        pageTitle: 'Global Logs',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Logs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/pro-forma-history',
      name: 'billing-pro-forma-history',
      component: () => import('@/views/billing/PendingProFormaInvoices.vue'),
      meta: {
        pageTitle: 'Pro Forma Invoice History',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Pro Forma History',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/pro-forma-history-limited',
      name: 'billing-pro-forma-history-limited',
      component: () => import('@/views/billing/PendingProFormaInvoices.vue'),
      meta: {
        pageTitle: 'Pro Forma Invoice History',
        action: 'read',
        resource: 'ProFormaHistoryLimited',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Pro Forma History',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/invoice-history',
      name: 'billing-invoice-history',
      component: () => import('@/views/billing/UserInvoiceHistory.vue'),
      meta: {
        pageTitle: 'Invoice History',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Invoice History',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/invoice-history-limited',
      name: 'billing-invoice-history-limited',
      component: () => import('@/views/billing/UserInvoiceHistory.vue'),
      meta: {
        pageTitle: 'Invoice History',
        action: 'read',
        resource: 'InvoicesHistoryLimited',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Invoice History',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/payment-history',
      name: 'billing-payment-history',
      component: () => import('@/views/billing/PaymentHistory.vue'),
      meta: {
        pageTitle: 'Payment History',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Payment History',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing/payment-history-limited',
      name: 'billing-payment-history-limited',
      component: () => import('@/views/billing/PaymentHistory.vue'),
      meta: {
        pageTitle: 'Payment History',
        action: 'read',
        resource: 'PaymentsHistoryLimited',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Payment History',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/billing/btc-transfer',
    //   name: 'billing-btc-transfer',
    //   component: () => import('@/views/billing/BtcTransfer.vue'),
    //   meta: {
    //     pageTitle: 'BTC Transfer',
    //     action: 'read',
    //     resource: 'Billing',
    //     breadcrumb: [
    //       {
    //         text: 'Billing',
    //         active: false,
    //       },
    //       {
    //         text: 'BTC Transfer',
    //         active: false,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/billing/balance',
      name: 'billing-balance',
      component: () => import('@/views/billing/invoice/BalancePayments.vue'),
      meta: {
        pageTitle: 'Balance Payments',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
          {
            text: 'Balance Payments',
            active: true,
          },
        ],
      },
    },

    {
      path: '/custom-invoices/create',
      name: 'custom-invoices-create',
      component: () => import('@/views/billing/custom-invoice/CreateInvoice.vue'),
      props: route => ({
        ...route.params,
      }),
      meta: {
        pageTitle: 'Create Custom Invoice',
        action: 'create',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Billing',
            to: '/billing/invoices-register',
          },
          {
            text: 'New Custom Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/custom-invoices/:id',
      name: 'custom-invoice-details',
      component: () => import('@/views/billing/custom-invoice/InvoiceDetails.vue'),
      meta: {
        pageTitle: 'Custom Invoice Details',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Billing',
            to: '/billing/invoices-register',
          },
          {
            text: 'Invoice Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/custom-invoice/preview',
      name: 'custom-invoice-preview',
      component: () => import('@/views/billing/custom-invoice/PreviewInvoice.vue'),
      props: route => ({
        ...route.params,
      }),
      meta: {
        pageTitle: 'Preview Invoice',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Invoice Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/custom-invoices/:id/edit',
      name: 'custom-invoice-edit',
      component: () => import('@/views/billing/custom-invoice/EditInvoice.vue'),
      meta: {
        pageTitle: 'Edit Custom Invoice',
        action: 'manage',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Billing',
            to: '/billing/invoices-register',
          },
          {
            text: 'Edit Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices/:id',
      name: 'invoice-details',
      component: () => import('@/views/billing/invoice/InvoiceDetails.vue'),
      meta: {
        pageTitle: 'Invoice Details',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            to: '/billing/invoices-register',
          },
          {
            text: 'Invoice Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices/:id/edit',
      name: 'invoice-edit',
      component: () => import('@/views/billing/invoice/EditInvoice.vue'),
      meta: {
        pageTitle: 'Edit Invoice',
        action: 'update',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Billing',
            to: '/billing/invoices-register',
          },
          {
            text: 'Update Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/current',
      name: 'invoice-current',
      component: () => import('@/views/billing/invoice/CurrentInvoice.vue'),
      meta: {
        pageTitle: 'Current Invoice',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Current Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/current/limited',
      name: 'invoice-current-limited',
      component: () => import('@/views/billing/invoice/CurrentInvoice.vue'),
      meta: {
        pageTitle: 'Current Invoice',
        action: 'read',
        resource: 'BillingLimited',
        breadcrumb: [
          {
            text: 'Current Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/current/:id',
      component: () => import('@/views/billing/invoice/CurrentInvoice.vue'),
      meta: {
        pageTitle: 'Current Invoice',
        action: 'read',
        resource: 'CurrentInvoice',
        breadcrumb: [
          {
            text: 'Current Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/custom/:id',
      component: () => import('@/views/billing/invoice/CurrentCustomInvoice.vue'),
      meta: {
        pageTitle: 'Current Invoice',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Current Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/paypal_status',
      component: () => import('@/views/billing/invoice/PaypalStatus.vue'),
      meta: {
        pageTitle: 'Current Invoice',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Current Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice/card_status',
      component: () => import('@/views/billing/invoice/CardStatus.vue'),
      meta: {
        pageTitle: 'Current Invoice',
        action: 'read',
        resource: 'Billing',
        breadcrumb: [
          {
            text: 'Current Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/messages/new-message',
      name: 'messages-new-message',
      component: () => import('@/views/messages/NewMessage.vue'),
      meta: {
        pageTitle: 'New Message',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
          {
            text: 'New',
            active: true,
          },
        ],
      },
    },
    {
      path: '/messages/received-messages',
      name: 'messages-received-messages',
      component: () => import('@/views/messages/ReceivedMessages.vue'),
      meta: {
        pageTitle: 'Received Messages',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
          {
            text: 'Received',
            active: true,
          },
        ],
      },
    },
    {
      path: '/messages/sent-messages',
      name: 'messages-sent-messages',
      component: () => import('@/views/messages/SentMessages.vue'),
      meta: {
        pageTitle: 'Sent Messages',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
          {
            text: 'Sent Messages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/messages/archived-messages',
      name: 'messages-archived-messages',
      component: () => import('@/views/messages/ArchivedMessages.vue'),
      meta: {
        pageTitle: 'Archived Messages',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Messages',
            active: true,
          },
          {
            text: 'Archived Messages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/optional-features',
      name: 'optional-features',
      component: () => import('@/views/OptionalFeatures.vue'),
      meta: {
        pageTitle: 'Optional Features',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Optional Features',
            active: true,
          },
        ],
      },
    },
    {
      path: '/optional-features-limited',
      name: 'optional-features-limited',
      component: () => import('@/views/OptionalFeatures.vue'),
      meta: {
        pageTitle: 'Optional Features',
        action: 'read',
        resource: 'OptionalFeaturesLimited',
        breadcrumb: [
          {
            text: 'Optional Features',
            active: true,
          },
        ],
      },
    },
    {
      path: '/platform',
      name: 'user-access-platform',
      component: () => import('@/views/Platform.vue'),
      meta: {
        pageTitle: 'Access Your Platform',
        action: 'read',
        resource: 'Platform',
        breadcrumb: [
          {
            text: 'Access Your Platform',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/news/List.vue'),
      meta: {
        pageTitle: 'Newsletter',
        action: 'read',
        resource: 'Af-User',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product_updates',
      name: 'product_updates',
      component: () => import('@/views/news/ProductUpdates.vue'),
      meta: {
        pageTitle: 'Product Updates',
        action: 'read',
        resource: 'Af-User',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/user-detail/:id',
      name: 'news',
      component: () => import('@/views/news/UserDetail.vue'),
      meta: {
        pageTitle: 'Newsletter',
        action: 'read',
        resource: 'Af-User',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
        ],
      },
    },
    {
      path: '/product-updates/user-detail/:id',
      name: 'product_updates',
      component: () => import('@/views/news/UserDetailProduct.vue'),
      meta: {
        pageTitle: 'Product Updates',
        action: 'read',
        resource: 'Af-User',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/list',
      name: 'news-list',
      component: () => import('@/views/news/ListVersion2.vue'),
      meta: {
        pageTitle: 'List News',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/add',
      name: 'news-add',
      component: () => import('@/views/news/Add.vue'),
      meta: {
        pageTitle: 'Add News',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/detail/:id',
      name: 'news-detail',
      component: () => import('@/views/news/Detail.vue'),
      meta: {
        pageTitle: 'News Detail',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/edit/:id',
      name: 'news-edit',
      component: () => import('@/views/news/Edit.vue'),
      meta: {
        pageTitle: 'News Edit',
        action: 'update',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Newsletter and Product Updates',
            active: true,
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-account',
      name: 'create-account',
      component: () => import('@/views/CreateAccount.vue'),
      meta: {
        pageTitle: 'Create Account',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Create Account',
            active: true,
          },
        ],
      },
    },
    {
      path: '/guides_videos',
      name: 'guides_videos',
      component: () => import('@/views/guides/Videos.vue'),
      meta: {
        pageTitle: 'Video Guides',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Video Guides',
            active: true,
          },
          {
            text: 'Videos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/guides_videos_casino',
      name: 'guides_videos_casino',
      component: () => import('@/views/guides/VideosCasino.vue'),
      meta: {
        pageTitle: 'Video Guides',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Video Guides',
            active: true,
          },
          {
            text: 'Videos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/guides_pdf/english',
      name: 'guides_pdf/english',
      component: () => import('@/views/guides/Pdf.vue'),
      meta: {
        pageTitle: 'Guides',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Guides',
            active: true,
          },
          {
            text: 'PDF (English)',
            active: true,
          },
        ],
      },
    },
    {
      path: '/guides_pdf/italian',
      name: 'guides_pdf/italian',
      component: () => import('@/views/guides/Pdf.vue'),
      meta: {
        pageTitle: 'Guides',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Guides',
            active: true,
          },
          {
            text: 'PDF (Italian)',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Update Profile',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/Account.vue'),
      meta: {
        pageTitle: 'Account',
        action: 'read',
        resource: 'Af-User',
        breadcrumb: [
          {
            text: 'My Account',
            active: true,
          },
        ],
      },
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Notifications.vue'),
      meta: {
        pageTitle: 'Notifications',
        action: 'read',
        resource: 'General',
        breadcrumb: [
          {
            text: 'All Notifications',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configuration',
      name: 'user-configuration',
      component: () => import('@/views/UserConfiguration.vue'),
      meta: {
        pageTitle: 'Configuration',
        action: 'read',
        resource: 'User',
        breadcrumb: [
          {
            text: 'System Identity',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        action: 'read',
        resource: 'Admin',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login/:hash',
      name: 'login-verify',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/unauthenticated',
      name: 'unauthenticated',
      component: () => import('@/views/error/NotLoggedIn.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      // ! Update import path
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// TESTING NEW METHOD FOR AUTH
router.beforeEach((to, _, next) => {
  const res = isUserLoggedIn()

  res.then(async data => {
    // Dacă nu există date, redirecționează către pagina de login
    if (!data) {
      const url = window.location.href
      const payload = encodeURIComponent(window.btoa(url))
      const param = '?i='
      window.location = `${process.env.VUE_APP_PORTAL}login${param}${payload}`
      return // Important să întoarcem aici pentru a evita continuarea codului
    }

    const { active, info } = data
    // Dacă utilizatorul nu este activ, redirecționează către 'registration_not_complete'
    if (active === 0 && to.path !== '/registration_not_complete') {
      window.location.href = '/registration_not_complete'
      return // Important să întoarcem aici pentru a evita continuarea codului
    }

    if (!canNavigate(to)) {
      if (!info) {
        if (to.name !== 'unauthenticated') {
          next({ name: 'unauthenticated' })
        }
        next()
      }

      if (to.name !== 'not-authorized') {
        next({ name: 'not-authorized' })
      }
      next()
    }

    if (to.meta.redirectIfLoggedIn && info) {
      next('/')
      return // Important să întoarcem aici pentru a evita continuarea codului
    }

    const user = await store.state.auth.userData
    if (user.isAdmin) {
      await store.dispatch('home/fetchAdminBadges')
    } else {
      await store.dispatch('home/fetchUserBadges')
    }

    next()
  })
})

export default router

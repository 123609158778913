import axios from '@/libs/axios'
// import { time } from 'console'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    invoiceData: {
      merged: null,
    },
    pendingCreditNotes: null,
    overdueCreditNotes: null,
    paymentRegister: null,
    paymentRegister2: null,
    paymentRegisterUser: null,
    affiliatePayments: null,
    wiretransferPayments: null,
    btctransferPayments: null,
    clientPaymentList: null,
    userInvoiceHistory: null,
    userInvoiceHistoryBalance: null,
  },
  getters: { },
  mutations: {
    SET_INVOICES_DATA(state, payload) {
      state.invoiceData = payload
    },
    SET_PENDING_CREDIT_NOTES(state, payload) {
      state.pendingCreditNotes = payload
    },
    SET_USER_INVOICE_HISTORY(state, payload) {
      state.userInvoiceHistory = payload
    },
    SET_USER_INVOICE_HISTORY_BALANCE(state, payload) {
      state.userInvoiceHistoryBalance = payload
    },
    SET_OVERDUE_CREDIT_NOTES(state, payload) {
      state.overdueCreditNotes = payload
    },
    SET_PAYMENT_REGISTER(state, payload) {
      state.paymentRegister = payload
    },
    SET_PAYMENT_REGISTER2(state, payload) {
      state.paymentRegister2 = payload
    },
    SET_PAYMENT_REGISTER_USER(state, payload) {
      state.paymentRegisterUser = payload
    },
    SET_AFFILIATE_PAYMENTS(state, payload) {
      state.affiliatePayments = payload
    },
    SET_WIRETRANSFER_PAYMENTS(state, payload) {
      state.wiretransferPayments = payload
    },
    SET_BTCTRANSFER_PAYMENTS(state, payload) {
      state.btctransferPayments = payload
    },
    SET_CLIENT_PAYMENTS(state, payload) {
      state.clientPaymentList = payload
    },
    DELETE_INVOICE(state, payload) {
      const index = state.invoiceData.merged.findIndex(item => item.id === payload)
      state.invoiceData.merged.splice(index, 1)
    },
  },
  actions: {
    async deleteCreditNote(ctx, creditNote) {
      await axios.delete(`/api/invoices/credit-notes/${creditNote}`)
    },
    fetchInvoices({ commit }, payload) {
      let last30days = 1
      if (typeof payload !== 'undefined') last30days = payload.last30days
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/invoices?last30days=${last30days}`)
          .then(response => {
            if (response.data) {
              // console.log('fetchInvoices', response.data)
              for (const item of response.data.merged) { // eslint-disable-line

                let isProFormaInvoice = 0
                if (item.invoiceType === 'invoice') {
                  const normal_invoice = Number(item.invoice)
                  const normal_invoice_number_v2 = Number(item.invoice_number_v2)
                  if (normal_invoice === 0 && normal_invoice_number_v2 === 0) {
                    isProFormaInvoice = 1
                  }
                }

                if (item.paidWith !== 'btc') {
                  isProFormaInvoice = 0
                }

                let invoiceNo = 0
                if (item.invoiceType === 'invoice' && item.version === 1 && item.invoice) invoiceNo = item.invoice
                else if (isProFormaInvoice === 1) invoiceNo = `Pro Forma #${item.credit_note}`
                else if (item.invoiceType === 'invoice' && item.version === 2 && item.is_credit_note === 0) invoiceNo = `TM${String(item.invoice_number_v2).padStart(4, '0')}`
                else if (item.invoiceType === 'custom' && item.version === 1) invoiceNo = item.invoiceNo
                else if (item.invoiceType === 'custom' && item.version === 2) invoiceNo = `TMC${String(item.invoice_number_v2).padStart(4, '0')}`

                item.customInvoiceNumber = invoiceNo

                if (!item.customInvoiceNumber) item.customInvoiceNumber = item.id

                let customAmount = 0
                if (item.invoiceType === 'custom') customAmount = `${item.currency} ${parseFloat(item.total).toFixed(2)}`
                else {
                  // const cur = item.currency.toUpperCase() === 'EUR' ? '€' : item.currency.toUpperCase()
                  const amount = parseFloat(item.invoice_value).toFixed(2)
                  // customAmount = `${cur} ${amount}`
                  customAmount = `€ ${amount}`
                }
                item.customAmount = customAmount

                const client_company = item.company
                const client_to = item.to
                let custom_site = ''

                const testerID = Number(item.tester)
                if (testerID === 99) item.customClient = 'ANULAT'
                else {
                  if (item.setting_network_type === 'subdomain') custom_site = `${item.setting_subdomain}.trafficmanager.com`
                  else custom_site = `${item.setting_sub_admin}.${item.setting_domain}`
                  if (client_company) custom_site = `${custom_site} - ${client_company}`
                  else if (client_to) custom_site = `${custom_site} - ${client_to}`
                  item.customClient = custom_site
                }

                let customIssueDate = 'N/A'
                if (item.invoiceType === 'custom') customIssueDate = moment(new Date(item.issueDate)).format('DD MMM YYYY')
                else if (response.data.paymentMap[item.credit_note]) customIssueDate = moment(new Date(response.data.paymentMap[item.credit_note].payment_date)).format('DD MMM YYYY')
                else if (typeof item.issue_date !== 'undefined') customIssueDate = moment(new Date(item.issue_date)).format('DD MMM YYYY')
                else if (typeof item.issueDate !== 'undefined') customIssueDate = moment(new Date(item.issueDate)).format('DD MMM YYYY')
                item.customIssueDate = customIssueDate

                let customDueDate = 'N/A'
                if (item.invoiceType === 'custom') customDueDate = moment(new Date(item.dueDate)).format('DD MMM YYYY')
                else if (typeof item.due_date !== 'undefined') customDueDate = moment(new Date(item.due_date)).format('DD MMM YYYY')
                else if (typeof item.dueDate !== 'undefined') customDueDate = moment(new Date(item.dueDate)).format('DD MMM YYYY')
                item.customDueDate = customDueDate

                let customPaidDate = ''
                if (response.data.paymentMap[item.credit_note]) customPaidDate = moment(new Date(response.data.paymentMap[item.credit_note].payment_date)).format('DD MMM YYYY')
                item.customPaidDate = customPaidDate
                if (testerID === 99) item.customPaidDate = item.customIssueDate

                let customStatus = ''
                if (item.invoiceType === 'custom') {
                  if (item.credit_note) customStatus = response.data.paymentMap[item.credit_note] ? response.data.paymentMap[item.credit_note].payment_status : ''
                  // eslint-disable-line
                  if (customStatus) customStatus = customStatus === 'Paid - Admin' ? 'Paid - Admin' : `Paid with ${customStatus}`
                  else customStatus = item.invoice_status === 'Paid' || item.invoice_status === 'pending' ? item.invoice_status : 'Overdue' // eslint-disable-line
                } else {
                  customStatus = response.data.paymentMap[item.credit_note] ? response.data.paymentMap[item.credit_note].payment_status : ''  // eslint-disable-line
                  if (customStatus) customStatus = customStatus === 'Paid - Admin' ? 'Paid - Admin' : `Paid with ${customStatus}`
                }

                item.customStatus = customStatus
                if (item.customStatus.trim() === '') item.customStatus = item.invoice_status

                let customTransactionRef = ''
                if(item.credit_note) customTransactionRef = response.data.paymentMap[item.credit_note] ? response.data.paymentMap[item.credit_note].ipc_trnref : '' // eslint-disable-line

                if (testerID === 99) item.customTransactionRef = 'N/A'
                else item.customTransactionRef = customTransactionRef

                const customClientType = item.uid ? 'Registered' : 'External'
                item.customClientType = customClientType

                let customInvoiceNumberForSort = 0
                const version = Number(item.version)
                if (item.invoiceType === 'custom' && version === 1) customInvoiceNumberForSort = Number(item.invoiceNo)
                else if (item.invoiceType === 'custom' && version === 2) customInvoiceNumberForSort = Number(item.invoice_number_v2) + 10000
                else if (item.invoiceType !== 'custom' && version === 1) customInvoiceNumberForSort = Number(item.invoice)
                else if (item.invoiceType !== 'custom' && version === 2) customInvoiceNumberForSort = Number(item.invoice_number_v2) + 10000
                else if (item.is_credit_note === 1) customInvoiceNumberForSort = Number(item.credit_note)
                item.customInvoiceNumberForSort = customInvoiceNumberForSort
              }
              commit('SET_INVOICES_DATA', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchInvoicesServer({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoices', payload)
          .then(response => {
            if (response.data) {
              commit('SET_INVOICES_DATA', response.data.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    deleteInvoice(ctx, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/invoices/${invoice}`, { data: { invoice } })
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAffiliatePayments({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/affiliates/get_payments')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                item.cumtomAmount = `€ ${parseFloat(item.commission_paid).toFixed(2)}`
                item.customRequestDate = moment(new Date(item.created_at)).format('DD MMM YYYY')

                let payment_account = ''
                const paymentStr = item.payment_method.toLowerCase()

                if (paymentStr.search('paypal') >= 0) payment_account = item.paypal_account
                else if (paymentStr.search('wire') >= 0) payment_account = item.iban
                else if (paymentStr.search('bitcoin') >= 0) payment_account = item.bitcoin
                item.customPaymentAccount = payment_account

                item.status = Number(item.payment_status)
                if (item.status === 0) item.customStatus = 'Pending'
                else item.customStatus = 'Paid'

                if (item.status === 0) item.customPaymentDate = 'N/A'
                else item.customPaymentDate = moment(new Date(item.updated_at)).format('DD MMM YYYY')
              }

              commit('SET_AFFILIATE_PAYMENTS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchWireTransferPayments({ commit }, isUser) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/wire_transfer/get_payments')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line

                const currency = item.currency === 'EUR' ? '€' : item.currency
                item.customAmount = `${currency} ${parseFloat(item.amount).toFixed(2)}`

                item.customRequestDate = moment(new Date(item.requestDate)).format('DD MMM YYYY hh:mm:ss')
                // item.customRequestDate = item.requestDate

                if (item.hasInvoiceNo) {
                  item.customInvoiceURI = '/custom-invoices'
                  item.customInvoiceLinkID = item.cutomInvoiceId
                  item.customInvoice = item.invoiceNo.toString()
                } else {
                  item.customInvoiceURI = '/invoices'
                  item.customInvoiceLinkID = item.invoiceId
                  item.customInvoice = item.creditNote.toString()
                }

                item.isUser = isUser

                if (isUser && item.table === 'custom_invoices') item.customInvoiceURI = '/invoice/custom'
                else if (isUser) item.customInvoiceURI = '/invoice/current'

                item.user = item.email

                if (item.approvalDate === 'N/A') item.customAppovalDate = 'N/A'
                else item.customAppovalDate = item.approvalDate
              }
              commit('SET_WIRETRANSFER_PAYMENTS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchBtcTransferPayments({ commit }, isUser) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/btc_transfer/get_btc_transfers')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line

                const currency = item.currency === 'EUR' ? '€' : item.currency
                item.customAmount = `${currency} ${parseFloat(item.amount).toFixed(2)}`

                item.customRequestDate = moment(new Date(item.requestDate)).format('DD MMM YYYY hh:mm:ss')
                // item.customRequestDate = item.requestDate

                if (item.hasInvoiceNo) {
                  item.customInvoiceURI = '/custom-invoices'
                  item.customInvoiceLinkID = item.cutomInvoiceId
                  item.customInvoice = item.invoiceNo.toString()
                } else {
                  item.customInvoiceURI = '/invoices'
                  item.customInvoiceLinkID = item.invoiceId
                  item.customInvoice = item.creditNote.toString()
                }

                item.customInvoiceURI = '/invoice/current'
                if (item.hasInvoiceNo) item.customInvoiceURI = '/invoice/custom'

                item.isUser = isUser
                item.user = item.email

                if (item.approvalDate === 'N/A') item.customAppovalDate = 'N/A'
                else item.customAppovalDate = item.approvalDate
              }
              commit('SET_BTCTRANSFER_PAYMENTS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchClientPaymentList({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/affiliates/client_request')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                item.customAmount = ` € ${parseFloat(item.commission_paid).toFixed(2)}`
                item.status = Number(item.payment_status)
                item.created_at = moment(new Date(item.created_at)).format('DD MMM YYYY')
                if (item.status === 0) {
                  item.customStatus = 'Pending'
                  item.customPaymentDate = 'N/A'
                } else {
                  item.customStatus = 'Paid'
                  item.customPaymentDate = moment(new Date(item.updated_at)).format('DD MMM YYYY')
                }
              }
              commit('SET_CLIENT_PAYMENTS', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchUserHistoryInvoice({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoices/history')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                if (item.table === 'invoices') {
                  item.customLinkDetail = `/invoice/current/${item.id}`

                  let isProFormaInvoice = 0
                  const normal_invoice = Number(item.invoice)
                  const normal_invoice_number_v2 = Number(item.invoice_number_v2)
                  if (normal_invoice === 0 && normal_invoice_number_v2 === 0) {
                    isProFormaInvoice = 1
                  }
                  let invoiceNo = 0
                  if (isProFormaInvoice === 1) invoiceNo = `Pro Forma #${item.credit_note}`
                  else if (normal_invoice) invoiceNo = `TM${String(normal_invoice).padStart(4, '0')}`
                  else if (normal_invoice_number_v2) invoiceNo = `TM${String(normal_invoice_number_v2).padStart(4, '0')}`

                  item.customInvoiceNo = invoiceNo

                  // if (item.is_credit_note) item.customInvoiceNo = `Invoice #${item.credit_note}`
                  // else item.customInvoiceNo = item.version === 1 ? `Invoice #${item.invoice}` : `TMC${String(item.invoice_number_v2).padStart(4, '0')}`

                  if (item.package !== 'none') {
                    item.customPackage = item.package
                  } else if (item.type !== item.type_opt_feature && item.type !== item.type_overage && item.item) item.customPackage = item.item
                  else if (item.type !== item.type_opt_feature && item.type !== item.type_overage && !item.item) item.customPackage = 'custom'
                  else if (item.type === item.type_opt_feature) item.customPackage = 'Optional feature'
                  else if (item.overage === 'conversions') item.customPackage = 'Optional Conversions'
                  else item.customPackage = 'Overage Clicks'

                  item.customInvoiceValue = ` ${item.currency.toUpperCase()} ${parseFloat(item.invoice_value).toFixed(2)} (inc.VAT ${item.vat}%)`
                } else {
                  item.customLinkDetail = `/invoice/custom/${item.id}`
                  // if (item.version === 1) item.customInvoiceNo = `Invoice #${item.invoiceNo}`
                  // else item.customInvoiceNo = `TMC${String(item.invoice_number_v2).padStart(4, '0')}`

                  let isProFormaInvoice = 0
                  let invoiceNo = 0
                  const normal_invoice = Number(item.invoiceNo)
                  const normal_invoice_number_v2 = Number(item.invoice_number_v2)
                  if (normal_invoice === 0 && normal_invoice_number_v2 === 0) {
                    isProFormaInvoice = 1
                  }
                  if (isProFormaInvoice === 1) invoiceNo = `Pro Forma #${item.credit_note}`
                  else if (normal_invoice) invoiceNo = `TMC${String(normal_invoice).padStart(4, '0')}`
                  else if (normal_invoice_number_v2) invoiceNo = `TMC${String(normal_invoice_number_v2).padStart(4, '0')}`
                  item.customInvoiceNo = invoiceNo

                  item.customPackage = 'Custom Product'
                  if (item.packageName) item.customPackage = item.packageName

                  item.customInvoiceValue = ` ${item.currency.toUpperCase()} ${parseFloat(item.total).toFixed(2)} (inc.VAT ${item.vatPercent}%)`
                }

                item.customPeriod = ''
                if (item.start_date && item.end_date) item.customPeriod = `${moment(item.start_date).format('DD MMM YYYY')} - ${moment(item.end_date).format('DD MMM YYYY')}`
              }

              commit('SET_USER_INVOICE_HISTORY', response.data.filter(item => item.type !== 6))
              resolve(response.data.filter(item => item.type !== 6))
            }
          })
          .catch(error => {
            reject(error)
            resolve(state.pendingCreditNotes) // eslint-disable-line
          })
      })
    },
    fetchUserHistoryInvoiceBalance({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoices/history')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line
                if (item.table === 'invoices') {
                  item.customLinkDetail = `/invoice/current/${item.id}`

                  let isProFormaInvoice = 0
                  const normal_invoice = Number(item.invoice)
                  const normal_invoice_number_v2 = Number(item.invoice_number_v2)
                  if (normal_invoice === 0 && normal_invoice_number_v2 === 0) {
                    isProFormaInvoice = 1
                  }
                  let invoiceNo = 0
                  if (isProFormaInvoice === 1) invoiceNo = `Pro Forma #${item.credit_note}`
                  else if (normal_invoice) invoiceNo = `TM${String(normal_invoice).padStart(4, '0')}`
                  else if (normal_invoice_number_v2) invoiceNo = `TM${String(normal_invoice_number_v2).padStart(4, '0')}`

                  item.customInvoiceNo = invoiceNo

                  // if (item.is_credit_note) item.customInvoiceNo = `Invoice #${item.credit_note}`
                  // else item.customInvoiceNo = item.version === 1 ? `Invoice #${item.invoice}` : `TMC${String(item.invoice_number_v2).padStart(4, '0')}`

                  if (item.package !== 'none') {
                    item.customPackage = item.package
                  } else if (item.type !== item.type_opt_feature && item.type !== item.type_overage && item.item) item.customPackage = item.item
                  else if (item.type !== item.type_opt_feature && item.type !== item.type_overage && !item.item) item.customPackage = 'custom'
                  else if (item.type === item.type_opt_feature) item.customPackage = 'Optional feature'
                  else if (item.overage === 'conversions') item.customPackage = 'Optional Conversions'
                  else item.customPackage = 'Overage Clicks'

                  item.customInvoiceValue = ` ${item.currency.toUpperCase()} ${parseFloat(item.invoice_value).toFixed(2)} (inc.VAT ${item.vat}%)`
                } else {
                  item.customLinkDetail = `/invoice/custom/${item.id}`
                  // if (item.version === 1) item.customInvoiceNo = `Invoice #${item.invoiceNo}`
                  // else item.customInvoiceNo = `TMC${String(item.invoice_number_v2).padStart(4, '0')}`

                  let isProFormaInvoice = 0
                  let invoiceNo = 0
                  const normal_invoice = Number(item.invoiceNo)
                  const normal_invoice_number_v2 = Number(item.invoice_number_v2)
                  if (normal_invoice === 0 && normal_invoice_number_v2 === 0) {
                    isProFormaInvoice = 1
                  }
                  if (isProFormaInvoice === 1) invoiceNo = `Pro Forma #${item.credit_note}`
                  else if (normal_invoice) invoiceNo = `TMC${String(normal_invoice).padStart(4, '0')}`
                  else if (normal_invoice_number_v2) invoiceNo = `TMC${String(normal_invoice_number_v2).padStart(4, '0')}`
                  item.customInvoiceNo = invoiceNo

                  item.customPackage = 'Custom Product'
                  if (item.packageName) item.customPackage = item.packageName

                  item.customInvoiceValue = ` ${item.currency.toUpperCase()} ${parseFloat(item.total).toFixed(2)} (inc.VAT ${item.vatPercent}%)`
                }

                item.customPeriod = ''
                if (item.start_date && item.end_date) item.customPeriod = `${moment(item.start_date).format('DD MMM YYYY')} - ${moment(item.end_date).format('DD MMM YYYY')}`
              }

              commit('SET_USER_INVOICE_HISTORY_BALANCE', response.data.filter(item => item.type === 6))
              resolve(response.data.filter(item => item.type === 6))
            }
          })
          .catch(error => {
            reject(error)
            resolve(state.pendingCreditNotes) // eslint-disable-line
          })
      })
    },
    fetchPendingCreditNotes({ commit, state }, is30Days) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/invoices/pending-credit-notes')
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line

                // create searchable invoice number
                let customNumber = ''
                if (item.is_credit_note) customNumber = item.credit_note
                else if (item.version === 1) customNumber = item.invoice
                else customNumber = `TMC${String(item.invoice_number_v2).padStart(4, '0')}`
                item.customNumber = customNumber.toString()

                // // create searchable package
                let customPackage = ''
                if (item.type !== 4 && item.type !== 3 && typeof item.invoice_rows !== 'undefined') customPackage = item.invoice_rows[0].item ?? 'Custom'
                else if (item.type === 4) customPackage = item.invoice_rows[0].item ?? 'Optional feature'
                else if (item.overage === 'conversions') customPackage = 'Overage Conversions'
                else customPackage = 'Overage Clicks'
                item.customPackage = (!item?.invoice_value && !item?.vat) ? '-' : customPackage

                if (!item?.invoice_value) {
                  item.invoice_value = item.total
                }

                if (item?.vat === null || item?.vat === undefined) {
                  item.vat = item.vatPercent ?? 0
                }

                let custom_site = ''
                const client_company = item.company
                const client_to = item.to

                const testerID = Number(item.tester)
                if (testerID === 99) item.customClient = 'ANULAT'
                else {
                  if (item.setting_network_type === 'subdomain') custom_site = `${item.setting_subdomain}.trafficmanager.com`
                  else custom_site = `${item.setting_sub_admin}.${item.setting_domain}`
                  if (client_company) custom_site = `${custom_site} - ${client_company}`
                  else if (client_to) custom_site = `${custom_site} - ${client_to}`
                  item.customClient = custom_site
                }

                // create searchable date
                item.customIssueDate = moment(item.issue_date).format('DD MMM YYYY')
              }
              // console.log('30 days: ', date)
              let { data } = response

              if (is30Days) {
                if (is30Days === 'thisMonth') {
                  const endDate = moment().format('YYYY-MM-DD')
                  const startDate = moment().startOf('month').format('YYYY-MM-DD')
                  data = response.data.filter(item => moment(item.issue_date).format('YYYY-MM-DD') >= startDate && moment(item.issue_date).format('YYYY-MM-DD') <= endDate)
                } else if (is30Days === 'lastMonth') {
                  const endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                  const startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                  data = response.data.filter(item => moment(item.issue_date).format('YYYY-MM-DD') >= startDate && moment(item.issue_date).format('YYYY-MM-DD') <= endDate)
                } else {
                  const date = moment().subtract(is30Days, 'days').format('YYYY-MM-DD')
                  data = response.data.filter(item => moment(item.issue_date).format('YYYY-MM-DD') >= date)
                }
              }

              commit('SET_PENDING_CREDIT_NOTES', data)
              resolve(data)
            }
          })
          .catch(error => {
            reject(error)
            resolve(state.pendingCreditNotes) // eslint-disable-line
          })
      })
    },
    fetchOverdueCreditNotes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/invoices/overdue-credit-notes?last30days=${payload.last30days}`)
          .then(response => {
            if (response.data) {
              for (const item of response.data) {  // eslint-disable-line

                let custom_site = ''
                const client_company = item.company
                const client_to = item.to

                const testerID = Number(item.tester)
                if (testerID === 99) item.customClient = 'ANULAT'
                else {
                  if (item.setting_network_type === 'subdomain') custom_site = `${item.setting_subdomain}.trafficmanager.com`
                  else custom_site = `${item.setting_sub_admin}.${item.setting_domain}`
                  if (client_company) custom_site = `${custom_site} - ${client_company}`
                  else if (client_to) custom_site = `${custom_site} - ${client_to}`
                  item.customClient = custom_site
                }

                // create searchable date
                item.customIssueDate = moment(item.issue_date).format('DD MMM YYYY')
              }
              commit('SET_OVERDUE_CREDIT_NOTES', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchPaymentRegister({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.paymentRegister === null) {
          axios
            .get('/api/invoices/payments-register')
            .then(response => {
              if (response.data) {
                commit('SET_PAYMENT_REGISTER', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.paymentRegister)
      })
    },
    fetchPaymentRegisterSSR(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/invoices/payments-register?page=${payload.page}`, payload)
          .then(response => {
            // console.log(response.data)
            if (response.data) {
              ctx.commit('SET_PAYMENT_REGISTER', response.data.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchPaymentRegisterSSR2(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/invoices/payments-register3?page=${payload.page}`, payload)
          .then(response => {
            // console.log(response.data)
            if (response.data) {
              ctx.commit('SET_PAYMENT_REGISTER2', response.data.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchCustomRecurrentSSR(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoices/custom-recurrent', data)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchCustomInterventionSSR(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoices/custom-intervention', data)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchPaymentRegisterUser({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.paymentRegisterUser === null) {
          axios
            .get('/api/invoices/payments-register')
            .then(response => {
              if (response.data) {
                commit('SET_PAYMENT_REGISTER_USER', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.paymentRegisterUser)
      })
    },
    fetchNewInvoiceDetails() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/custom-invoices/new-invoice-details')
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    getClientInvoiceDetails(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/custom-invoices/client-invoice-details/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    getClientInvoiceDetailsByUid(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/custom-invoices/client-invoice-details-by-uid/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    getInvoiceByID(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/invoices/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    getCustomInvoiceByID(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/custom-invoices/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    getEditCustomInvoiceByID(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/custom-invoices/${id}/edit`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    storeNewInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/custom-invoices', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateCustomInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/custom-invoices/${payload.id}/update`, payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/invoices/${payload.id}`, payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateStatusInvoice(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/invoices/update/status/${id}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateInvoiceStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/invoices/update_status', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateCustomInvoiceStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/custom-invoices/update_status', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    getPaypalUrl(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/paypal/get_pay_url', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    getPaypalStatus() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/paypal/get_status')
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}

import axios from '@/libs/axios'
import VueCookies from 'vue-cookies'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    adminData: null,
    userData: null,
    userBadges: null,
    adminBadges: null,
    limited: false,
  },
  getters: { },
  mutations: {
    SET_USER_DATA(state, payload) {
      state.userData = payload
    },
    SET_ADMIN_DATA(state, payload) {
      state.adminData = payload
    },
    REMOVE_INVOICE(state, index) {
      Vue.delete(state.adminData.invoices, index)
    },
    SET_USER_BADGES(state, payload) {
      state.userBadges = payload
    },
    SET_ADMIN_BADGES(state, payload) {
      state.adminBadges = payload
    },
    SET_LIMITED_USER(state, payload) {
      state.limited = payload
    },
  },
  actions: {
    setLimited({ commit }) {
      const limitedCookie = VueCookies.get('limited')
      if (limitedCookie) {
        commit('SET_LIMITED_USER', true)
      }
    },
    fetchUser({ commit, state }) {
      const limitedCookie = VueCookies.get('limited')
      if (limitedCookie) {
        commit('SET_LIMITED_USER', true)
      }
      return new Promise((resolve, reject) => {
        if (state.userData === null) {
          axios
            .get('/api/home/user')
            .then(response => {
              if (response.data) {
                commit('SET_USER_DATA', response.data)
                resolve(response.data)
              }
            })
            .catch(error => reject(error))
        } else resolve(state.userData)
      })
    },
    fetchUserRefresh({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/home/user')
          .then(response => {
            if (response.data) {
              commit('SET_USER_DATA', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAdminBadges({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/menu/admin_badges')
          .then(response => {
            if (response?.data) {
              commit('SET_ADMIN_BADGES', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAdminBadgesReset({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/menu/admin_badges')
          .then(response => {
            if (response.data) {
              commit('SET_ADMIN_BADGES', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchUserBadges({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/menu/user_badges')
          .then(response => {
            if (response?.data) {
              commit('SET_USER_BADGES', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchUserBadgesReset({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/menu/user_badges')
          .then(response => {
            if (response.data) {
              commit('SET_USER_BADGES', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    resetUser({ commit }, obj) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/home/user?startDate=${obj.startDate}&endDate=${obj.endDate}`)
          .then(response => {
            if (response.data) {
              commit('SET_USER_DATA', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchAdmin(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/home/admin/${data}`)
          .then(response => {
            if (response.data) {
              ctx.commit('SET_ADMIN_DATA', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    deleteInvoice(ctx, invoiceNo) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/billing/invoice', { data: { invoiceNo } })
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    hideInvoice(ctx, invoice) {
      if (invoice.type === 'custom') {
        return new Promise((resolve, reject) => {
          axios.post('/api/custom-invoices/hide-invoice', invoice).then(response => {
            if (response.data) resolve(response.data)
          }).catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios.post('/api/invoices/hide-invoice', invoice).then(response => {
          if (response.data) resolve(response.data)
        }).catch(error => reject(error))
      })
    },
  },
}

import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    userList: null,
  },
  getters: { },
  mutations: {
    SET_USER_LIST(state, payload) {
      state.userList = payload
    },
  },
  actions: {
    fetchUserListUpgrades({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/packages/user_list_upgrades')
          .then(response => {
            if (response.data) {
              commit('SET_USER_LIST', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}

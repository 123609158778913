<template>
  <div
    id="app"
    class="h-100"
  >
    <component :is="layout">
      <router-view />
    </component>
    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
// import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import VueCookies from 'vue-cookies'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      this.modifyPageTitle()
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    cookiesValue() {
      const cookieName = this.getCookieNameUID()
      const cookies = {
        first: {
          name: VueCookies.isKey('limited') ? 'limited' : null,
          value: VueCookies.get('limited'),
        },
        second: null,
      }
      if (cookieName) {
        cookies.second = {
          name: cookieName,
          value: VueCookies.get(cookieName),
        }
      }
      return cookies
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  mounted() {
    this.modifyPageTitle()
    setInterval(() => {
      if (!this.cookiesValue || !this.cookiesValue.first.name) return
      const cookieName = this.getCookieNameUID()
      if (this.cookiesValue.first.name === 'limited' && !VueCookies.isKey('limited')) {
        if (cookieName) VueCookies.remove(cookieName)
        VueCookies.remove('limited')
        this.logout()
        return
      }
      if (!cookieName || cookieName !== this.cookiesValue.second.name || !VueCookies.isKey(cookieName)) {
        VueCookies.remove('limited')
        VueCookies.remove(cookieName)
        this.logout()
        return
      }
      if (this.cookiesValue.first.value !== VueCookies.get('limited') || this.cookiesValue.second.value !== VueCookies.get(cookieName)) {
        VueCookies.remove('limited')
        VueCookies.remove(cookieName)
        this.logout()
      }
    }, 1000)
  },
  methods: {
    getCookieNameUID() {
      return VueCookies.keys().find(key => key.match(/^uid_\d+$/)) || null
    },
    logout() {
      localStorage.clear()
      this.$http.post('/api/logout')
        .then(() => {
          window.location.href = `${process.env.VUE_APP_PORTAL}dashboard-logout`
        })
        .catch(() => {
          window.location.href = `${process.env.VUE_APP_PORTAL}dashboard-logout`
        })
    },
    modifyPageTitle() {
      const refreshId = setInterval(() => {
        const { pageTitle } = this.$router.currentRoute.meta
        if (pageTitle) {
          let { title } = document
          title = title.split('|')
          if (typeof title[1] === 'undefined') title = title[0].trim() // eslint-disable-line
          else title = title[1].trim() // eslint-disable-line

          if (!this.$store.state.auth?.userData?.isAdmin) title = title.replace('Admin', 'Customer')

          document.title = `${pageTitle} | ${title}`
          clearInterval(refreshId)
        }
      }, 250)
    },
  },
  setup() {
    // const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    // if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      // skinClasses,
      enableScrollToTop,
    }
  },
}
</script>

import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    featureData: null,
  },
  mutations: {
    SET_OPTIONAL_FEATURE_DATA(state, payload) {
      state.featureData = payload
    },
  },
  actions: {
    fetchOptionalFeatures({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/optional_feature/get_features')
          .then(response => {
            if (response.data) {
              commit('SET_OPTIONAL_FEATURE_DATA', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },

    toggleOptionalFeatures(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/optional_feature/save_features', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },

  },
}

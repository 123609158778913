import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    settingsData: null,
    customDNSData: null,
  },
  getters: {
    getSettingByUID: state => uid => state.settingsData.find(obj => obj.uid === uid),
  },
  mutations: {
    SET_SETTINGS_DATA(state, payload) {
      state.settingsData = payload
    },
    SET_CUSTOM_DNS_DATA(state, payload) {
      state.customDNSData = payload
    },
  },
  actions: {
    saveDNS(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/settings/user/save_dns', payload)
          .then(response => {
            if (response.status === 200) {
              if (response.data) {
                resolve(response.data)
              }
            } else {
              reject(response)
            }
          })
          .catch(error => reject(error))
      })
    },
    updateDNS(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/settings/user/update_dns', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    deleteDNS(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/settings/user/delete_dns', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    redirectRootWWW(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/settings/user/redirect_rw', payload)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    /* eslint-disable */
    fetchCustomDNS({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/settings/user/custom_dns')
          .then(response => {
            if (response?.data) {
              if(response.data?.error != 'none') reject('This feature is not available when not using TrafficManager DNS.')

              const { ttls } = response.data
              for (const item of response.data.records) { // eslint-disable-line
                item.customTtl = ttls[item.ttl]
                if (item.proxied === false) item.customProxyStatus = 'DNS only'
                else item.customProxyStatus = 'Proxied'
              }
              commit('SET_CUSTOM_DNS_DATA', response.data.records)
              resolve(response.data)
            }
            else {
              reject(response)
            }
          })
          .catch(error => {
            console.log(error)
          })
      })
    },
    fetchSettings({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/settings')
          .then(response => {
            if (response.data) {
              commit('SET_SETTINGS_DATA', response.data)
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    fetchSetting(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/settings/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
    resetSettings(ctx, uid) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/settings/reset/${uid}`)
          .then(response => {
            if (response.data) {
              resolve(response.data)
            }
          })
          .catch(error => reject(error))
      })
    },
  },
}
